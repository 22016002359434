<template>
	<r-data-list
		:headdata="headdata"
		:listdata="listData.data"
		:opt="opts"
		v-model:editMode="editmode"
		v-model:addMode="addmode"
		v-if="loaded"
		:searchfilter="searchfilter"
		v-model:qu="q"
		><template v-slot:rowextrabtn="{ datarow }">
			<r-btn
				:disabled="editmode || addmode"
				@click="openSubList(datarow)"
				v-if="!editmode && !addmode"
				bgcolor="#003642"
				>Alt Kategoriler</r-btn
			>
		</template></r-data-list
	>

	<r-panel
		:title="subListInfo.title + ' Alt kategorileri'"
		v-if="subCatPanel"
		v-model:isactive="subCatPanel"
		classes="r-flx-dw-80"
		pos="center"
	>
		<r-data-list
			:headdata="subheaddata"
			:listdata="listSubData.data"
			:opt="subopts"
			:searchfilter="searchfilterc"
			v-model:qu="qc"
		/>
	</r-panel>
</template>
<script>
import { onBeforeMount, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { trToUpper } from '@/mix/global'
export default {
	setup() {
		const store = useStore()
		const listData = ref({})
		const listSubData = ref({})
		const editmode = ref(false)
		const addmode = ref(false)
		const subCatPanel = ref(false)
		const subListInfo = ref({})
		const pid = ref(null)
		const loaded = ref(false)
		const q = ref('')
		const qc = ref('')
		const searchfilter = v => {
			return v.title && trToUpper(v.title).includes(trToUpper(q.value))
		}
		const searchfilterc = v => {
			return v.title && trToUpper(v.title).includes(trToUpper(qc.value))
		}

		const headdata = computed(() => {
			return [
				{
					title: 'ID',
					style: { width: '5%' },
					key: 'id',
					edit: false,
					hide: true,
				},
				{
					title: 'Kategori Adı',
					style: { width: '70%' },
					key: 'title',
					valid: 'required',
				},
			]
		})
		const subheaddata = computed(() => {
			return [
				{
					title: 'ID',
					style: { width: '5%' },
					key: 'id',
					hide: true
				},
				{
					title: 'Alt Kategori Adı',
					style: { width: '70%' },
					key: 'title',
					valid: 'required',
				},
			]
		})
		const opts = ref({
			title: 'Kategoriler',
			buttons: {
				style: { width: '25%' },
				edit: {
					show:true,
					type: 'local',
					savefunc: d => {
						saveLine(d)
					},
				},
				delete: {
					show:true,
					func: d => {
						deleteFunc(d)
					},
				},
				add: {
					show:true,
					type: 'in',
					func: d => {
						addFunc(d)
					},
				},
			},
		})
		const subopts = ref({
			title: 'Alt Kategoriler',
			buttons: {
				style: { width: '25%' },
				edit: {
					show:true,
					type: 'local',
					savefunc: d => {
						saveSubLine(d)
					},
				},
				delete: {
					show:true,
					func: d => {
						deleteSubFunc(d)
					},
				},
				add: {
					show:true,
					type: 'in',
					func: d => {
						addFunc(d)
					},
				},
			},
		})
		const getList = async () => {
			let list = await store.dispatch('getDatas', {
				api: 'categories/getsubcategories',
				params: {
					pid: pid.value ? parseInt(pid.value) : 0,
				},
			})
			return list
		}
		const openSubList = async d => {
			subListInfo.value = d
			pid.value = d.id
			listSubData.value = await getList()
			subCatPanel.value = true
		}

		const addFunc = async d => {
			d.id = 0
			d.pid = pid.value ? parseInt(pid.value) : 0
			await store.dispatch('postDatas', {
				api: 'categories/addcategory',
				data: d,
			})
			pid.value
				? (listSubData.value = await getList())
				: (listData.value = await getList())
		}
		const deleteFunc = async d => {
			await store.dispatch('postDatas', {
				api: 'categories/deletecategory',
				data: { id: parseInt(d.row.id) },
			})
			listData.value = await getList()
		}
		const deleteSubFunc = async d => {
			await store.dispatch('postDatas', {
				api: 'categories/deletecategory',
				data: { id: parseInt(d.row.id) },
			})
			listSubData.value = await getList()
		}
		const saveLine = async d => {
			d.pid = parseInt(d.pid)
			await store.dispatch('postDatas', {
				api: 'categories/addcategory',
				data: d,
			})
			listData.value = await getList()
		}
		const saveSubLine = async d => {
			d.pid = parseInt(d.pid)
			await store.dispatch('postDatas', {
				api: 'categories/addcategory',
				data: d,
			})
			listSubData.value = await getList()
		}
		watch(
			() => subCatPanel.value,
			() => {
				if (subCatPanel.value === false) {
					pid.value = null
				}
			}
		)
		onBeforeMount(async () => {
			listData.value = await getList()
			loaded.value = true
		})
		return {
			headdata,
			subheaddata,
			opts,
			subopts,
			listData,
			listSubData,
			saveLine,
			saveSubLine,
			addFunc,
			editmode,
			addmode,
			subCatPanel,
			openSubList,
			subListInfo,
			loaded,
			q,
			qc,
			searchfilter,
			searchfilterc,
		}
	},
}
</script>
